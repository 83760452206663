import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import PilihCustomer from './Cust/PilihCustomer';
import BuatCustomer from './Cust/BuatCustomer';
import DashboardTransaksi from './Transaksi/Reguler/DashboardTransaksi';
import UploadFoto from './Transaksi/UploadFoto/UploadFoto';
import CetakNota from './Transaksi/Nota/CetakNota';
import Pembayaran from './Transaksi/Pembayaran/Pembayaran';
import DataOrderan from './Transaksi/Pesanan/DataOrderan';
import Pengambilan from './Transaksi/Pesanan/Pengambilan';
import FotoPengambil from './Transaksi/Pesanan/FotoPengambil';
import BeliVoucher from './Transaksi/Voucher/BeliVoucher';
import Pengeluaran from './Transaksi/Pengeluaran/Pengeluaran';
import ListProduksi from './Produksi/ListProduksi';
import DetailProduksi from './Produksi/DetailProduksi';
import ProsesProduksi from './Produksi/ProsesProduksi';
import RekapHarian from './Rekap/RekapHarian';
import history from './history';

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/login" component={Login} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/pilih-cust" component={PilihCustomer} />
                    <Route path="/daftar-pelanggan" component={BuatCustomer} />
                    <Route path="/tambah-transaksi" component={DashboardTransaksi} />
                    <Route path="/up-foto" component={UploadFoto} />
                    <Route path="/cetak-nota" component={CetakNota} />
                    <Route path="/pembayaran" component={Pembayaran} />
                    <Route path="/cari-pesanan" component={DataOrderan} />
                    <Route path="/pengambilan-transaksi" component={Pengambilan} />
                    <Route path="/foto-pengambilan-transaksi" component={FotoPengambil} />
                    <Route path="/beli-voucher" component={BeliVoucher} />
                    <Route path="/list-produksi" component={ListProduksi} />
                    <Route path="/detail-produksi" component={DetailProduksi} />
                    <Route path="/proses-produksi" component={ProsesProduksi} />
                    <Route path="/rekap-harian" component={RekapHarian} />
                    <Route path="/pengeluaran" component={Pengeluaran} />
                </Switch>
            </Router>
        )
    }
}