import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import history from './../../history';
import { IoArrowBack } from "react-icons/io5";
import {formatMoney} from './../../custom-func';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2'

const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class DataOrderan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            list_transaksi: []
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.cariOrderan = this.cariOrderan.bind(this);
        this.goToNext = this.goToNext.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.ksr != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }


            this.cariOrderan()
        }
    }

    cariOrderan(){
        fetch(REACT_APP_API_URL+'get-list-transaksi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token'),
                pelanggan: this.query.get('kdCust')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status == true){
                this.setState({
                    list_transaksi: responseJson.data
                })
            }
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }


    goToNext(kdTrx){
        history.push('cetak-nota?kdTx='+kdTrx+'&back=1&ambil=1')
    }

    render() {  
        const dataPelanggan = this.state.list_transaksi.map((val, id) => {
            return (
                <div key={val.kode_transaksi+Math.random()} className="border m-2" onClick={() => this.goToNext(val.kode_transaksi)}>
                    <div className="p-1 m-1">
                        <div className="border-bottom pb-2 font-weight-bold color-kc">{val.nomor_nota}</div>

                        <div className="row pt-1">
                            <div className="col-9">
                                <div className="font-weight-bold">{val.nama_pelanggan}</div>
                                <div className="font-10 text-grey ">{val.tlp_pelanggan}</div>
                                <div className="font-weight-bold color-kc ">Rp {formatMoney(val.total_nett)}</div>
                            </div>
                            <div className="col-3">
                                <CircularProgressbar value={val.persen_prod} text={`${val.persen_prod}%`}/>    
                            </div>
                        </div>
                        <div className=" border-top mt-2"></div>
                        <div className="row pt-1">
                            <div className="col-6">
                                <div className="text-grey">Tanggal Terima</div>
                            </div>
                            <div className="col-6">
                                <div className="text-grey text-right">{val.tgl_transaksi}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="text-grey">Tanggal Selesai</div>
                            </div>
                            <div className="col-6">
                                <div className="text-grey text-right">{val.tanggal_selesai}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-11">
                                <h4>List Transaksi</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="pt-reg">
                        {dataPelanggan}
                    </div>
                </div>
            </div>
        );
    }
}



export default DataOrderan;
