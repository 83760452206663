import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import history from './../../history';
import { IoArrowBack } from "react-icons/io5";
import {formatMoney} from './../../custom-func';
import {Form, Container, Button} from 'react-bootstrap'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2'


const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})

const {
    REACT_APP_API_URL,
} = process.env;

class BeliVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            list_voucher: []
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.getVoucher = this.getVoucher.bind(this);
        this.goToNext = this.goToNext.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            const level = localStorage.getItem('level');
            const role = JSON.parse(localStorage.getItem('role'));
            if(level < 2 || role.ksr != 1 ){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }

            this.getVoucher()
        }
    }

    getVoucher(){
        fetch(REACT_APP_API_URL+'get-list-voucher', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token'),
                pelanggan: this.query.get('kdCust')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status == true){
                this.setState({
                    list_voucher: responseJson.data
                })
            }
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }


    goToNext(kdTrx){
        this.props.history.replace('pembayaran?kdTx='+kdTrx+'&kdCust='+this.query.get('kdCust')+'&voc=1')
    }

    render() {  
        const dataVoucher = this.state.list_voucher.map((val, id) => {
            return (
                <div key={val.kode_voucher+Math.random()} className="border m-2" onClick={() => this.goToNext(val.kode_voucher)}>
                    <div className="p-1 m-1">
                        <div className="row">
                            <div className="col-7">
                                <div className="pb-2 font-weight-bold color-kc mt-1">{val.nama_voucher}</div>
                            </div>
                            <div className="col-5">
                        <div className="font-weight-bold color-kc mt-1 text-right">Rp {formatMoney(val.total_harga_voucher)}</div>
                            </div>
                        </div>

                        <div className="border-bottom mb-2"></div>
                        {
                            val.detail.map((vd, kd) => {
                                return (
                                    <div key={kd} >
                                        <div className="text-grey">- {vd.nama_item} : {vd.isi_voucher} {vd.nama} </div>
                                    </div>
                                )
                            })
                        }


                    </div>
                </div>
            );
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-11">
                                <h4>List Voucher</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="pt-reg">
                        {dataVoucher}
                    </div>
                </div>
            </div>
        );
    }
}



export default BeliVoucher;
