import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Custom-css.css';
import Spinner from './../Spinner'
import history from './../history';
import {Form, Container, Button} from 'react-bootstrap'
import { IoArrowBack } from "react-icons/io5";
import Swal from 'sweetalert2'


const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class BuatCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            nama_pelanggan:'',
            tlp_pelanggan:'',
            email:'',
            alamat:'',
        };
        this.query = new URLSearchParams(this.props.location.search);
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.konfirmasi = this.konfirmasi.bind(this);
        this.goToNext = this.goToNext.bind(this);

    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.ksr != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    

    konfirmasi(){
        swalKC.fire({
            title: 'Simpan data pelanggan?',
            text: "Data akan di simpan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Simpan'
          }).then((result) => {
            if (result.isConfirmed) {
                // this.setState({kdHeadPakaian: kdHeadPakaian, kdDetPakaian: kdDetPakaian})
                this.buatPelanggan()
            }
          })
    }



    buatPelanggan(){
        fetch(REACT_APP_API_URL+'buat-pelanggan', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nama_pelanggan: this.state.nama_pelanggan,
                tlp_pelanggan: this.state.tlp_pelanggan,
                email_pelanggan: this.state.email,
                alamat_pelanggan: this.state.alamat,
                
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status == true){
                swalKC.fire({
                    title: 'Berhasil',
                    text: responseJson.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        this.goToNext(responseJson.data.kdCust)
                    }
                  })
            }else{
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: responseJson.message
                })
            }
            
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }

    goToNext(kdCust){
        // isinya sama buat customer
        let ke = this.query.get('ke');
        if(ke === 'tx'){
            this.props.history.push('tambah-transaksi?kdCust='+kdCust)
        }

        if(ke === 'cari_tx'){
            this.props.history.push('cari-pesanan?kdCust='+kdCust)
        }
        
    }

    render() {  

        return (
            this.state.disabled == true ?
            <Spinner /> 
            :
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-11">
                                <h4>Buat Data Pelanggan</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-reg pb-reg">
                    <Container>
                    
                        <Form.Control
                            type="text"
                            placeholder="Nama Pelanggan"
                            className="br0 form-input mb-3"
                            name="nama_pelanggan" 
                            value={this.state.nama_pelanggan}
                            onChange={this.handleInputChange}
                        />
                    
                        <Form.Control
                            type="number"
                            placeholder="Telepon Pelanggan (WA)"
                            className="br0 form-input mb-3"
                            name="tlp_pelanggan" 
                            value={this.state.tlp_pelanggan}
                            onChange={this.handleInputChange}
                        />
                    
                        <Form.Control
                            type="text"
                            placeholder="Email (Tidak Wajib)"
                            className="br0 form-input mb-3"
                            name="email" 
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                    
                        <Form.Control
                            type="text"
                            placeholder="Alamat (Tidak Wajib)"
                            className="br0 form-input mb-3"
                            name="alamat" 
                            value={this.state.alamat}
                            onChange={this.handleInputChange}
                        />

                    </Container>
                </div>

                <div className="fix-bottom">

                    <Container>
                        <div className="row">
                            <div className="col-12  p-1">
                                <Button className="btn-light w-100 btn-kc-outline text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={() => this.konfirmasi()}><span className="color-kc">Simpan Pelanggan</span></Button>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}



export default BuatCustomer;
