import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Custom-css.css';
import {Form, Container, Button} from 'react-bootstrap';

import history from './../history';
import { IoArrowBack, IoQrCodeOutline } from "react-icons/io5";
import Swal from 'sweetalert2';

const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class ProsesProduksi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            jenis_pekerjaan:null,
            total_item:null,
            lokasi_proses:null,
            catatan:null,
            pekerjaan: [],
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.konfirmProduksi = this.konfirmProduksi.bind(this);
        this.getListPekerjaan = this.getListPekerjaan.bind(this);
        this.simpanPengerjaan = this.simpanPengerjaan.bind(this);
        this.KirimPenyelesaian = this.KirimPenyelesaian.bind(this);
        this.kirimInformasi = this.kirimInformasi.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            let ke = this.query.get('ke');  
            if(ke != 'masuk_rak'){
                
                const level = localStorage.getItem('level');
                const role = JSON.parse(localStorage.getItem('role'));
                if(level < 3 || role.pkj != 1 ){
                    swalKC.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Maaf anda tidak memiliki akses di fitur ini',
                        showCancelButton: false,
                        confirmButtonText: 'Kembali'
                        }).then((result) => {
                            history.goBack()
                        })
                }
            }

            this.getListPekerjaan()
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }


    getListPekerjaan(){
        let detTrx = this.query.get('detTrx');
        fetch(REACT_APP_API_URL+'get-status-produksi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token'),
                ke: this.query.get('ke'),
                kode_detail_transaksi: detTrx,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status === true){
                this.setState({
                    pekerjaan: responseJson.data,
                    jenis_pekerjaan:responseJson.data[0]['kode_pekerjaan']
                })
            }else{
                history.goBack()
            }

        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }

    konfirmProduksi(){
        swalKC.fire({
            title: 'Konfirmasi Produksi?',
            text: "Proses produksi akan di simpan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Simpan Produksi'
          }).then((result) => {
            if (result.isConfirmed) {
                // this.setState({kdHeadPakaian: kdHeadPakaian, kdDetPakaian: kdDetPakaian})
                this.simpanPengerjaan()
            }
          })
    }

    simpanPengerjaan(){
        let ke = this.query.get('ke');
        fetch(REACT_APP_API_URL+'simpan-pengerjaan', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_pekerjaan: this.state.jenis_pekerjaan,
                total_item: this.state.total_item,
                lokasi_proses: this.state.lokasi_proses,
                catatan: this.state.catatan,
                kode_detail_tx: this.query.get('detTrx'),
                kode_transaksi: this.query.get('kode_transaksi'),
                kode_layanan: this.query.get('kode_layanan'),
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status == true){
                swalKC.fire({
                    title: 'Berhasil',
                    text: responseJson.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        if(ke == 'masuk_rak'){
                            this.KirimPenyelesaian()
                        }else{
                            history.goBack()
                        }
                    }
                  })
            }else{
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: responseJson.message
                })
            }
            
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }

    KirimPenyelesaian(){
        swalKC.fire({
            title: 'Kirim Pengambilan?',
            text: "kirimkan informasi penyelesaian pesanan dan pengambilan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Kirim Informasi'
          }).then((result) => {
            if (result.isConfirmed) {
                // this.setState({kdHeadPakaian: kdHeadPakaian, kdDetPakaian: kdDetPakaian})
                this.kirimInformasi()
            }
          })
    }


    kirimInformasi(){
        fetch(REACT_APP_API_URL+'notifikasi-penyelesaian', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_detail_tx: this.query.get('detTrx'),
                kode_transaksi: this.query.get('kode_transaksi'),
                kode_layanan: this.query.get('kode_layanan'),
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            window.open(responseJson.data, "_blank")
            history.goBack()
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }
    
    render() {  
        const jenisPekerjaan = this.state.pekerjaan.map((val, id) => {
            return (
                <option key={val.kode_pekerjaan} value={val.kode_pekerjaan} >{val.nama_pekerjaan}</option>
            )
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-9">
                                <h4>Proses Produksi</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-reg">
                    <Container>
                        Jenis Pekerjaan Selanjutnya
                        <Form.Control as="select" value={this.state.jenis_pekerjaan} className="br0 form-input mb-3" name="jenis_pekerjaan" disabled={this.state.disabled} onChange={this.handleInputChange}> 
                            {jenisPekerjaan}
                        </Form.Control>

                        Total Item
                        <Form.Control
                            type="number"
                            placeholder="contoh : 10"
                            className="br0 form-input mb-3"
                            name="total_item" 
                            value={this.state.total_item}
                            disabled={this.state.disabled} 
                            onChange={this.handleInputChange}
                            />


                        Lokasi Proses Pekerjaan
                        <Form.Control
                            type="text"
                            placeholder="contoh : MC 1"
                            className="br0 form-input mb-3"
                            name="lokasi_proses" 
                            value={this.state.lokasi_proses}
                            disabled={this.state.disabled} 
                            onChange={this.handleInputChange}
                            />

                        Catatan
                        <Form.Control
                            as="textarea"
                            placeholder="contoh : total packing / rusak atau lainnya"
                            className="br0 form-input mb-3"
                            name="catatan" 
                            value={this.state.catatan}
                            rows={3}
                            disabled={this.state.disabled} 
                            onChange={this.handleInputChange}
                            />
                        
                        <Button variant="light" className="btn-kc-outline  color-kc mt-3" block disabled={this.state.disabled}  onClick={() => this.konfirmProduksi()}>
                            Konfirmasi Pekerjaan
                        </Button>
                    </Container>
                </div>
            </div>
        );
    }
}



export default ProsesProduksi;
