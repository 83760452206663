import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import history from './../../history';
import { IoArrowBack } from "react-icons/io5";
import {formatMoney} from './../../custom-func';
import {Form, Container, Button} from 'react-bootstrap'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2'

const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class Pengeluaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            list_biaya: [],
            jenis_akun: "",
            total_biaya: "",
            deskripsi: ""
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.getDataBiaya = this.getDataBiaya.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.simpanBiaya = this.simpanBiaya.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.bya != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }

            this.getDataBiaya()
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    getDataBiaya(){
        fetch(REACT_APP_API_URL+'get-list-akun-biaya', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status == true){
                this.setState({
                    list_biaya: responseJson.data
                })
            }
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }


    simpanBiaya(){
        this.setState({ loading: 1 })

        fetch(REACT_APP_API_URL+'simpan-biaya', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token'),
                jenis_akun: this.state.jenis_akun,
                total_biaya: this.state.total_biaya,
                deskripsi: this.state.deskripsi,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({ loading: 0 })
                swalKC.fire({
                    title: 'Berhasil',
                    text: responseJson.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        history.goBack()
                    }
                  })
            }else{
                this.setState({ loading: 0 })
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: responseJson.message
                })
            }
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }

    render() {  
        const dataBiaya = this.state.list_biaya.map((val, id) => {
            return (
                <option key={val.kode_akun} value={val.kode_akun} >{val.acc_desc}</option>
            );
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-11">
                                <h5>Tambahkan Pengeluaran</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="pt-reg">
                        <Container>
                            Jenis Biaya
                            <Form.Control as="select" value={this.state.jenis_akun} className="br0 form-input mb-3" name="jenis_akun" disabled={this.state.disabled} onChange={this.handleInputChange}> 
                                <option key={0} value={0} >Pilih Akun</option>
                                {dataBiaya}
                            </Form.Control>

                            Total Pengeluaran
                            <Form.Control
                                type="number"
                                placeholder="contoh : 10000"
                                className="br0 form-input mb-3"
                                name="total_biaya" 
                                value={this.state.total_biaya}
                                disabled={this.state.disabled} 
                                onChange={this.handleInputChange}
                                />

                            Deskripsi
                            <Form.Control
                                type="text"
                                placeholder="contoh : Beli Gas"
                                className="br0 form-input mb-3"
                                name="deskripsi" 
                                value={this.state.deskripsi}
                                disabled={this.state.disabled} 
                                onChange={this.handleInputChange}
                                />
                                
                            <Button variant="light" className="btn-kc-outline  color-kc mt-3" block disabled={this.state.disabled}  onClick={() => this.simpanBiaya()}>
                                Simpan Pengeluaran
                            </Button>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}



export default Pengeluaran;
