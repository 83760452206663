import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Custom-css.css';
import {Form, Container, Button} from 'react-bootstrap'

import InputGroup from 'react-bootstrap/InputGroup'
import history from './../history';
import { IoArrowBack, IoQrCodeOutline } from "react-icons/io5";
import {formatMoney} from './../custom-func'
import { RiSearchEyeLine } from "react-icons/ri";
import { CircularProgressbar } from 'react-circular-progressbar';
import Swal from 'sweetalert2'


const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class RekapHarian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            rekap: []
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.getListRekap = this.getListRekap.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') == null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.rkap != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }
            this.getListRekap()
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    getListRekap(){

        fetch(REACT_APP_API_URL+'get-list-rekap-pay-harian', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({
                    rekap: responseJson.data
                })
            }else{
                this.setState({
                    rekap: []
                })
            }

            
        })
        .catch((error) => {
            console.error(error);
            this.setState({ loading: 0 })
        });
    }
    
    render() {  
        const lstProduksi = this.state.rekap.map((val, id) => {
            return (
                <div key={val.jenis.kode_akun} className="mt-2 pb-4">
                    <div className="font-weight-bold">{val.jenis.acc_desc}</div>
                    {
                        val.detail.map((v, i) => {
                            return (
                                <div  key={v.kode_pembayaran}>
                                    <div className="border p-1 pl-3 mb-1" style={{fontSize:15}}>
                                        <div className="font-weight-bold"> Pembayaran : {v.nomor_pembayaran} </div>
                                        <div>Nota : {v.nomor_nota}</div>
                                        <div>Jam : {v.jam_payment}</div>
                                        <div>Nominal : Rp {formatMoney(v.total_pembayaran)}</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        });

        const totalHarga = this.state.rekap.map((val, id) => {
            return (
                <div key={val.jenis.kode_akun} className="row">
                    <div className="col-6 font-weight-bold">{val.jenis.acc_desc}</div>
                    <div className="col-6 text-right">{formatMoney(val.jenis.total)}</div>
                </div>
            );
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-9">
                                <h5>Rekap Pembayaran Harian</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-reg">
                    <Container>
                        {lstProduksi}
                    </Container>
                        <div className="border-top p-2">
                            <Container>
                                {totalHarga}
                            </Container>
                        </div>
                </div>
            </div>
        );
    }
}



export default RekapHarian;
