import React, { Component } from 'react';
import invoke from 'react-native-webview-invoke/browser'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import '../../../custom-input-file.css';
import Spinner from './../../Spinner'
import {Container, Button} from 'react-bootstrap'
import history from './../../history';
import {ucWords, formatMoney, formatDateDFYHI, formatAngka} from './../../custom-func'
import { IoPrint, IoLogoWhatsapp, IoArrowBack} from "react-icons/io5";
import Swal from 'sweetalert2'

const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class CetakNota extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            selesai:'',
            delete: 0,
            print_color: 'text-danger',
            transaksi: [],
            payment: [],
            detail_transaksi: []
        };
        this.query = new URLSearchParams(this.props.location.search);

        this.getDetailItem = this.getDetailItem.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.ksr != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }

            this.getDetailItem()
        }
    }

    getDetailItem(){

        fetch(REACT_APP_API_URL+'get-transaksi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdTx: this.query.get('kdTx'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            this.setState({
                detail_transaksi: responseJson.data.detail,
                transaksi: responseJson.data.header,
                payment: responseJson.data.payment,
                selesai: responseJson.data.selesai,
                delete: responseJson.data.delete,
            })
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }

    hapusNota(){
        let ts = this
        swalKC.fire({
            title: 'Alasan Pembatalan ?',
            icon: 'warning',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: 'Ya, Hapus!',
            preConfirm: (text) => {
                return fetch(REACT_APP_API_URL+'hapus-transaksi', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        kode_user: localStorage.getItem('kode_user'),
                        schema: localStorage.getItem('schema'),
                        kdTx: ts.query.get('kdTx'),
                        alasan_batal: text,
                        api_token: localStorage.getItem('token')
                    })
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson.status == true){
                        swalKC.fire({
                            icon: 'success',
                            title: 'Berhasil!',
                            text: responseJson.message,
                            timer: 3000,
                            timerProgressBar: true
                        }).then((result) => {
                            history.replace('/')
                        })
                    }else{
                        swalKC.fire(
                            'Gagal!',
                            responseJson.message
                        )
                    }
                })
                .catch(error => {
                    Swal.showValidationMessage(
                    `Request failed: ${error}`
                    )
                })
            },

          })
    }


    render() {  
        const ts = this
        const kdTrx = this.query.get('kdTx')
        const goToPrint = invoke.bind('goToPrint')
        const konekBluetooth = invoke.bind('konekBluetooth')
        const printCust = async function() {
            swalKC.fire(
                'Cetak Nota!',
                'Silahkan periksa printer anda. Printer mungkin membutuhkan waktu beberapa saat. Pastikan HP anda terkoneksi dengan internet!'
            )
            var url = REACT_APP_API_URL+"get-nota-cust?kdTx="+kdTrx+"&schema="+localStorage.getItem('schema')
            await goToPrint(url)
        }

        const printToko = async function() {
            swalKC.fire(
                'Cetak Nota!',
                'Silahkan periksa printer anda. Printer mungkin membutuhkan waktu beberapa saat. Pastikan HP anda terkoneksi dengan internet!'
            )
            var url = REACT_APP_API_URL+"get-nota-toko?kdTx="+kdTrx+"&schema="+localStorage.getItem('schema')
            await goToPrint(url)
        }

        const notaWA = async function() {
            var url = REACT_APP_API_URL+"get-nota-wa?kdTx="+kdTrx+"&schema="+localStorage.getItem('schema')
            await goToPrint(url)
        }

        const konekB = async function() {
            await konekBluetooth()
        }

        

        invoke.define('changePrint', upPrint)
        invoke.define('statusPrint', statusPrint)
        function upPrint(hasil) {
            if(hasil === 1){
                ts.setState({
                    print_color: 'text-success'
                })
            }
        }
        
        function statusPrint(status) {
            swalKC.close()
            if(status === 'sukses'){
                swalKC.fire(
                    'Cetak nota berhasil!',
                    'Periksa printer anda.'
                )
            }else{
                swalKC.fire(
                    'Cetak nota gagal!',
                    'Silahkan coba print lagi.'
                )
            }
        }


        const detPayment = this.state.payment.map((val, id) => {
            return (
                <div key={val.kode_pembayaran} className="mb-3">
                    <div className="row">
                        <div className="col-6 text-grey">
                            <div>{formatDateDFYHI(val.tanggal_pembayaran)}</div>
                            <div>{val.bank}</div>
                        </div>
                        <div className="col-6 text-right text-grey">
                            <div>{val.nomor_pembayaran}</div>
                            <div>Rp {formatMoney(val.total_pembayaran)}</div>
                        </div>
                    </div>
                </div>
            )
        });

        const detailItemTx = this.state.detail_transaksi.map((val, id) => {
            return (
                <div key={val.kode_detail_tx} className="border mb-3">

                    <div className="w-100 border-bottom">
                        <div className="p-1 pl-2">
                            <div className="font-weight-bold" >{ucWords(val.nama_item)}</div>

                            {
                                val.total_luas > 0 ?
                                    <div className="text-grey">{formatAngka(val.panjang_item)} x {formatAngka(val.lebar_item)} {val.nama_satuan} = {formatAngka(val.total_luas)} {val.nama_satuan}</div>
                                    : null
                            }
                                    
                            <div className="row"> 
                                <div className="col-8">
                                    {
                                        val.total_luas > 0 ?
                                            <div className="text-grey">{formatAngka(val.total_luas)} {val.nama_satuan} x {formatAngka(val.total_item)} x Rp {formatMoney(val.harga_layanan)}</div>
                                            :
                                            <div className="text-grey">{formatAngka(val.total_item)} {val.nama_satuan} x Rp {formatMoney(val.harga_layanan)}</div>
                                    }
                                </div>

                                <div className="col-4 text-grey text-right">
                                    Rp {formatMoney(val.total_harga)}
                                </div>
                            </div>
                            
                            <div className="font-10 text-grey mt-2">Catatan : {val.notes ? val.notes : '-'}</div>
                            <div className="font-10 text-grey">Status : {val.stat_desc}</div>

                            {
                                val.jenis_pakaian != null ?
                                    JSON.parse(val.jenis_pakaian).map((v, i) => {
                                        return(
                                        <span key={v.kode_pakaian} className="font-10 text-grey">
                                            {' '} {ucWords(v.nama_pakaian)} : {v.jumlah_pakaian} PCS | 
                                        </span>
                                    )})
                                : null
                            }

                        </div>
                    </div>

                    <div className="border-bottom w-100">
                        <div style={{overflowX:'auto', display:'flex'}}>
                        {   
                            val.gambar != null ?
                            JSON.parse(val.gambar).map((v, i) => {
                                return(
                                    <div style={{flex: '0 0 25%', height:90}}  key={i} className="p-1" >
                                        <img src={"https://kc-imgs.oss-ap-southeast-5.aliyuncs.com/kc/transaksi/"+localStorage.getItem('schema')+"/"+v+"?x-oss-process=image/resize,m_fixed,w_100,h_80"} />
                                    </div>
                                )
                            })
                            : null
                        }
                        </div>
                    </div>

                </div>
            );
        });


        return (
            this.state.disabled == true ?
            <Spinner /> 
            :
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            {
                                this.query.get('back') == 1 ?
                                <div className="col-1" onClick={() => history.goBack()}>
                                    <h5><IoArrowBack /></h5>
                                </div>
                                :
                                <div className="col-1" onClick={() => history.replace('/')}>
                                    <h5><IoArrowBack /></h5>
                                </div>
                            }
                            <div className="col-9">
                                <h5 className="mt-1">{this.state.transaksi.nomor_nota}</h5>
                            </div>
                            <div className="col-1" onClick={() => konekB() }>
                                <h4 className="m-0 p-0"><IoPrint className={this.state.print_color} /></h4>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="pt-reg pb-detailTx">
                    <Container>
                        <div className="row">
                            <div className="col-6">
                                <div className="font-weight-bold">{this.state.transaksi.nama_pelanggan}</div>    
                            </div>
                            <div className="col-6 text-right">
                                <div>{this.state.transaksi.tlp_pelanggan}</div>    
                            </div>
                        </div>


                        <div className="detail-transaksi">
                            <div className="row">
                                <div className="col-4">
                                    <div className="white font-10">Sisa Tagihan</div>  
                                    {
                                        this.state.transaksi.sisa_pembayaran > 0 ?
                                            <div className="font-weight-bold white">Rp {formatMoney(this.state.transaksi.sisa_pembayaran)}</div>    
                                        :
                                            <div className="font-weight-bold white">Lunas</div>    
                                    }  
                                </div>
                                {
                                    this.state.transaksi.dibatalkan_oleh == null ?
                                        <div className="col-8 mt-1">
                                            {
                                                this.query.get('ambil') == 1 ?
                                                    this.state.transaksi.tanggal_ambil == null ?
                                                        <Button className="btn-light btn-kc-outline text-white btn-sm float-right ml-1" onClick={ () => history.push('/pengambilan-transaksi?kdTx='+this.query.get('kdTx')) } ><span className="color-kc">Ambil </span></Button>
                                                    : null
                                                : null
                                            }

                                            {
                                                this.state.transaksi.sisa_pembayaran > 0 ?
                                                        <Button className="btn-light btn-kc-outline text-white btn-sm float-right ml-1" onClick={ () => history.push('/pembayaran?kdTx='+this.query.get('kdTx')) } ><span className="color-kc">Bayar </span></Button>
                                                : null
                                            }
                                        </div>
                                    : null
                                }
                            </div>
                        </div>

                        {
                            this.state.transaksi.dibatalkan_oleh != null ?
                                <div class="alert alert-danger" role="alert">
                                    Batal Batal: {formatDateDFYHI(this.state.transaksi.tanggal_batal)} <br/>
                                    Alasan : {this.state.transaksi.alasan_batal}
                                </div>
                            : 
                            <div className="text-center mb-3">
                                Estimasi Selesai
                                <h5>{this.state.selesai}</h5>    
                            </div>   
                        }

                        {detailItemTx}

                        {
                            this.state.payment.length > 0 ?
                            <div>
                                <div className="text-center mb-3 pt-3">
                                    History pembayaran
                                </div>   
                                {detPayment}
                            </div>
                        : null
                        }

                        {
                            this.state.transaksi.dibatalkan_oleh == null ?
                                this.state.payment.length == 0 ?
                                    this.state.delete == 1 ?
                                        <Button variant="outline-danger" block onClick={ () => this.hapusNota() }>Hapus Transaksi</Button>
                                    : null
                                : null
                            : null
                        }

                        <div className="row pt-3 mt-3 border-top">
                            <div className="col-9 text-grey text-right">
                                Total Gross &nbsp;&nbsp; Rp
                            </div>
                            <div className="col-3 text-right text-grey">
                                {formatMoney(this.state.transaksi.total_gross)}
                            </div>

                            <div className="col-9 text-grey text-right">
                                Total Disc &nbsp;&nbsp; Rp
                            </div>
                            <div className="col-3 text-right text-grey">
                                {formatMoney(this.state.transaksi.disc_amount)}
                            </div>

                            <div className="col-9 text-grey text-right">
                                Pembulatan &nbsp;&nbsp; Rp
                            </div>
                            <div className="col-3 text-right text-grey">
                                {formatMoney(this.state.transaksi.pembulatan)}
                            </div>

                            <div className="col-9 text-grey text-right">
                                Total Nett &nbsp;&nbsp; Rp
                            </div>
                            <div className="col-3 text-right text-grey">
                                {formatMoney(this.state.transaksi.total_nett)}
                            </div>

                            <div className="col-9 text-success font-weight-bold text-right">
                                Total Bayar &nbsp;&nbsp; Rp
                            </div>
                            <div className="col-3 text-right text-success font-weight-bold">
                                {formatMoney(this.state.transaksi.total_pembayaran)}
                            </div>

                            <div className="col-9 text-danger font-weight-bold text-right">
                                Sisa Pembayaran &nbsp;&nbsp; Rp
                            </div>
                            <div className="col-3 text-right text-danger font-weight-bold">
                                {formatMoney(this.state.transaksi.sisa_pembayaran)}
                            </div>
                        </div>

                    </Container>
                </div>

                {
                    this.state.transaksi.dibatalkan_oleh == null ?
                        <div className="fix-bottom">
                            <Container>
                                <div className="row">
                                    <div className="col-4  p-1">
                                        <Button className="btn-light w-100 btn-kc-outline text-white font-10" disabled={this.state.disabled ? 'disabled' : ''} block onClick={ () => notaWA() } ><span className="color-kc"><h4 className="m-0 p-0"><IoLogoWhatsapp /></h4> Whatsapp </span></Button>
                                    </div>
                                    <div className="col-4  p-1">
                                        <Button className="btn-light w-100 btn-kc-outline text-white font-10" disabled={this.state.disabled ? 'disabled' : ''} block onClick={ () => printCust() } ><span className="color-kc"><h4 className="m-0 p-0"><IoPrint /></h4>  Pelanggan </span></Button>
                                    </div>
                                    <div className="col-4  p-1">
                                        <Button className="btn-light w-100 btn-kc-outline text-white font-10" disabled={this.state.disabled ? 'disabled' : ''} block onClick={ () => printToko() } ><span className="color-kc"><h4 className="m-0 p-0"><IoPrint /></h4>  Produksi</span></Button>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    : null
                }
            </div>
        );
    }
}



export default CetakNota;
