import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import '../../../custom-input-file.css';
import Spinner from './../../Spinner'
import {Form, Container, Button} from 'react-bootstrap'
import history from './../../history';
import { IoCloseCircle } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import Swal from 'sweetalert2'


const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class Pengambilan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            nama_pengambil:'',
            foto_customer:[],
            offset:0,
            nxt:1,
            transaksi: []
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.onImageChange = this.onImageChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.konfirmAmbil = this.konfirmAmbil.bind(this);
        this.konfirmasiPengambilan = this.konfirmasiPengambilan.bind(this);

    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.ksr != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }

        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    onImageChange(event) {
        const target = event.target;
        const name = target.name;
        console.log(name)
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            // console.log(event.target.files[0])
            // this.setState({
            //     foto_customer: event.target.files[0], // URL.createObjectURL(img)
            // });


            // swalKC.fire({
            //     text: 'Simpan foto ini?',
            //     imageUrl: URL.createObjectURL(img),
            //     imageWidth: '100%',
            //     imageHeight: '500px',
            //     imageAlt: 'Custom image',
            //     showCancelButton: true,
            //     cancelButtonText: 'Batal',
            //     confirmButtonText: 'Ya, Simpan!'
            //   }).then((result) => {
            //     if (result.isConfirmed) {
            //         this.uploadImage(img)
            //     }
            //   })
        }
    }

    konfirmAmbil(){
        swalKC.fire({
            title: 'Simpan Pengambilan?',
            text: "Pengambilan tidak dapat dibatalkan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Ya, Ambil!'
          }).then((result) => {
            if (result.isConfirmed) {
                // this.setState({kdHeadPakaian: kdHeadPakaian, kdDetPakaian: kdDetPakaian})
                this.konfirmasiPengambilan()
            }
          })
    }



    konfirmasiPengambilan(){

        this.setState({disabled:true})
        let formData = new FormData();
        formData.append("kd_trx", this.query.get('kdTx'));
        // formData.append("img", this.state.foto_customer);
        formData.append("nama_pengambil", this.state.nama_pengambil);
        formData.append("kode_user", localStorage.getItem('kode_user'));
        formData.append("schema", localStorage.getItem('schema'));
        formData.append("api_token", localStorage.getItem('token'));

        fetch(REACT_APP_API_URL+'pengambilan-transaksi', {method: "POST", body: formData})
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            
            if(responseJson.status == true){
                swalKC.fire({
                    title: 'Berhasil',
                    text: responseJson.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        // history.goBack()
                        history.replace('/foto-pengambilan-transaksi?kdTx='+this.query.get('kdTx'))
                    }
                  })
            }else{
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: responseJson.message
                })
            }

            this.setState({disabled:false})
        })
        .catch((error) => {
            console.error(error);
        
            swalKC.fire({
                icon: 'error',
                title: 'Oops...',
                text: error
            })
            this.setState({disabled:false})
        });
    }


    onImageDel(){
        this.setState({
            foto_customer: ''
        })
    }
    

    render() {  

        return (
            this.state.disabled == true ?
            <Spinner /> 
            :
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-11">
                                <h4>Nama Pengambil</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-reg pb-reg">
                    <Container>
                    
                        <Form.Control
                        type="text"
                        placeholder="Nama Pelanggan Yang Ambil"
                        className="br0 form-input mb-3"
                        name="nama_pengambil" 
                        value={this.state.nama_pengambil}
                        onChange={this.handleInputChange}
                        />
                    </Container>
                </div>

                <div className="fix-bottom">
                        <Container>
                            <div className="row">
                                <div className="col-12  p-1">
                                    <Button className="btn-light w-100 btn-kc-outline text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={ () => this.konfirmAmbil() } ><span className="color-kc">Konfirmasi Pengambilan</span></Button>
                                </div>
                            </div>
                        </Container>
                    </div>
            </div>
        );
    }
}



export default Pengambilan;
