import React, { Component } from 'react';
import invoke from 'react-native-webview-invoke/browser'
import Form from 'react-bootstrap/Form'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import '../../../custom-input-file.css';

import {Container, Button} from 'react-bootstrap'
import history from './../../history';
import { formatMoney } from './../../custom-func'
import { IoArrowBack } from "react-icons/io5";
import Swal from 'sweetalert2'

const {
    REACT_APP_API_URL,
} = process.env;

const swalKC = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


class Pembayaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            jenis: [],
            sisa: 0,
            kembalian: 0,
            total_payment: 0,
            jenis_payment: 0,
        };
        this.query = new URLSearchParams(this.props.location.search);

        this.TotalBayar = this.TotalBayar.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.ksr != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }

            this.getDetailPayment()
        }
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if(name == 'total_payment'){
            this.TotalBayar(value.replace(/[^0-9]/g, ''), 0)
        }

    }
    
    getDetailPayment(){
        fetch(REACT_APP_API_URL+'get-detail-payment', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdTx: this.query.get('kdTx'),
                voc: this.query.get('voc'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            this.setState({
                sisa : responseJson.data.sisa,
                total_payment : responseJson.data.sisa,
                jenis : responseJson.data.jenis,
            })
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }

    


    TotalBayar(val, lanjut = 0) {
        var sisa = this.state.sisa;
        
        var t_payment, kembalian = 0;
        if (val === 'c') {
            t_payment = 0;
        } else if (val === 'f') {
            t_payment = this.state.sisa;
        } else {
            t_payment = val
            if(lanjut == 1){
                t_payment = this.state.total_payment + val
            }
        }

        t_payment = t_payment
        if (parseFloat(t_payment) > parseFloat(sisa)) {
            kembalian = t_payment - sisa;
        }

        this.setState({
            total_payment: t_payment,
            kembalian: kembalian
        })
    }


    konfirmPayment(){
        swalKC.fire({
            title: 'Simpan Pembayaran?',
            text: "Pembayaran akan di simpan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Bayar'
          }).then((result) => {
            if (result.isConfirmed) {
                // this.setState({kdHeadPakaian: kdHeadPakaian, kdDetPakaian: kdDetPakaian})
                this.simpanPembayaran()
            }
          })
    }


    simpanPembayaran(){
        var uri_url = 'konfirmasi-payment-reguler'
        if(this.query.get('voc') == 1){
            uri_url = 'konfirmasi-payment-voucher'
        }

        fetch(REACT_APP_API_URL+uri_url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tipe_pembayaran: this.state.jenis_payment,
                total_payment: this.state.total_payment,
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kode_transaksi: this.query.get('kdTx'),
                kdCust: this.query.get('kdCust'),
                voc: this.query.get('voc'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status == true){
                swalKC.fire({
                    title: 'Berhasil',
                    text: responseJson.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        history.goBack()
                    }
                  })
            }else{
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: responseJson.message
                })
            }
            
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }

    render() {       
        const jenisPayment = this.state.jenis.map((val, id) => {
            return (
                <option key={val.kode_akun} value={val.kode_akun} >{val.acc_desc}</option>
            )
        });

        return (
            <div className="bg-color">
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={() => history.goBack()}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-9">
                                <h4>Pembayaran</h4>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="pt-reg pb-detailTx">
                    <Container className="w-90 justify-content-center">
                        Sisa Pembayaran : 
                        <h1>{formatMoney(this.state.sisa)}</h1>

                        <div className="pt-3">
                            <Form.Control as="select" value={this.state.jenis_payment} className="br0 form-input" name="jenis_payment" disabled={this.state.disabled} onChange={this.handleInputChange}> 
                                {jenisPayment}
                            </Form.Control>


                            <div className="row mt-4">
                                <div className="col-6 mb-3">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled} block onClick={() => this.TotalBayar(10000, 1)}> 10.000</Button>
                                </div>
                                <div className="col-6 mb-3">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled} block onClick={() => this.TotalBayar(20000, 1)}> 20.000</Button>
                                </div>
                                <div className="col-6 mb-3">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled} block onClick={() => this.TotalBayar(50000, 1)}> 50.000</Button>
                                </div>
                                <div className="col-6 mb-3">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled} block onClick={() => this.TotalBayar(100000, 1)}> 100.000</Button>
                                </div>
                                <div className="col-6 mb-3">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled} block onClick={() => this.TotalBayar('c')}> Hapus </Button>
                                </div>
                                <div className="col-6 mb-3">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled} block onClick={() => this.TotalBayar('f')}> Uang Pas</Button>
                                </div>
                            </div>


                            <div className="border-top pt-3 mt-3 pb-5">
                                <Form.Control
                                    type="text"
                                    placeholder="Total Pembayaran : 0"
                                    className="br0 form-input pr-5"
                                    name="total_payment" 
                                    value={formatMoney(this.state.total_payment)}
                                    disabled={this.state.disabled} 
                                    onChange={this.handleInputChange}
                                    />


                                <Button variant="light" className="btn-kc-outline  color-kc mt-3" block disabled={this.state.disabled}  onClick={() => this.konfirmPayment()}>
                                    Konfirmasi Pembayaran
                                </Button>
                            </div>

                            Kembalian : 
                            <h2>{formatMoney(this.state.kembalian)}</h2>
                            
                        </div>
                        
                    </Container>
                </div>
            </div>
        );
    }
}



export default Pembayaran;
