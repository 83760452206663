import { useState } from "react";
import { css } from "@emotion/core";
import DotLoader from "react-spinners/DotLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  color: #f76300;
`;

function Spinner() {
  return (
    <div className="sweet-loading" style={{paddingTop:'70%'}}>
      <DotLoader loading={true} css={override} color="#f76300" size={80} />
      <p className="text-center mt-3">Loading . . .</p>
    </div>
  );
}

export default Spinner;