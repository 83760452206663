import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import '../../../custom-input-file.css';
import invoke from 'react-native-webview-invoke/browser'
import Spinner from './../../Spinner'
import {Form, Container, Button} from 'react-bootstrap'
import history from './../../history';
import { IoCloseCircle } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import Swal from 'sweetalert2'


const swalKC = Swal.mixin({
    customClass: {
        header: 'p-0 m-0',
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


const {
    REACT_APP_API_URL,
} = process.env;

class FotoPengambil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            disabled: false,
            foto_pengambil: '',
        };
        this.query = new URLSearchParams(this.props.location.search);
        this.getFotoPengambil = this.getFotoPengambil.bind(this);

    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            this.getFotoPengambil()
        }
    }

    getFotoPengambil(){

        fetch(REACT_APP_API_URL+'get-foto-pengambill', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdTx: this.query.get('kdTx'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            this.setState({
                foto_pengambil: responseJson.data
            })
        })
        .catch((error) => {
            console.error(error);
            this.setState({disabled:false})
        });
    }

    render() {  

        let ts = this;
        const pilihFile = invoke.bind('pilihFile')
        const uploadFile = async function() {
            var url = REACT_APP_API_URL+'foto-pengambil-transaksi';
            var detTrx = ts.query.get('kdTx');
            var kode_user = localStorage.getItem('kode_user');
            var token = localStorage.getItem('token');
            var schema = localStorage.getItem('schema');
            await pilihFile(url, detTrx, kode_user, token, schema)
        }

        invoke.define('statusGambar', statusGambar)
        function statusGambar(status) {
            ts.getFotoPengambil()
        }

        return (
            this.state.disabled == true ?
            <Spinner /> 
            :
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-11">
                                <h4>Foto Pengambil</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-reg pb-reg">
                    
                    {
                        this.state.foto_pengambil == '' ?
                        <Container>
                            <Button className="btn-light w-100 btn-kc color-kc text-white br-0" block onClick={ () => uploadFile() } > <h6 className="m-0 p-0">Upload Foto Pengambil</h6> </Button>
                        </Container>
                        :
                        <div className="p-1">
                            <img src={"https://kc-imgs.oss-ap-southeast-5.aliyuncs.com/kc/ambil/"+localStorage.getItem('schema')+"/"+this.state.foto_pengambil+"?x-oss-process=image/resize,m_fixed,w_400,h_600"} style={{maxWidth:'100%'}} />
                        </div>
                    }
                </div>

                <div className="fix-bottom">
                        <Container>
                            <div className="row">
                                <div className="col-12  p-1">
                                    <Button className="btn-light w-100 btn-kc-outline text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={ () => history.replace('/') } ><span className="color-kc">Kembali Ke Dashboard</span></Button>
                                </div>
                            </div>
                        </Container>
                    </div>
            </div>
        );
    }
}



export default FotoPengambil;
