import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Custom-css.css';
import {Form, Container, Button} from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import Swal from 'sweetalert2'

import Table from 'react-bootstrap/Table'
import history from './../../history';
import { IoArrowBack, IoCloseCircle } from "react-icons/io5";
import { TiPlusOutline, TiTag, TiArrowRightOutline } from "react-icons/ti";
import {ucWords, formatMoney, formatDateDFY, formatAngka } from './../../custom-func'
import Modal from 'react-bootstrap/Modal'
import { RiSearchEyeLine } from "react-icons/ri";
import { BsPlusSquare, BsXCircleFill } from "react-icons/bs";

const {
    REACT_APP_API_URL,
} = process.env;

const swalKC = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-kc-outline btn-sm color-kc',
        cancelButton: 'btn btn-outline-dark btn-sm ml-2'
    },
    buttonsStyling: false
})


class DashboardTransaksi extends Component {
    constructor(props) {
        super(props);
        this.query = new URLSearchParams(this.props.location.search);
        this.state = {
            hidden: true,
            disabled: false,
            keywords:'',
            kd_kategori:'',
            item: [],
            kategori: [],
            pakaian: [],
            total_gross:0,
            disc_amount:0,
            pembulatan:0,
            nett:0,
            txDetail: [],
            txOneDetail: [],
            jenis_promo: [],
            jenis: 0,
            modalItem: false,
            modalItemDetail: false,
            modalPakaian: false,
            modalPromo: false,
            kdHeadPakaian:'',
            kdDetPakaian:'',
            nama_promo:'',
        };

        this.cariItem = this.cariItem.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addItemDetail = this.addItemDetail.bind(this);
        this.getItemDetail = this.getItemDetail.bind(this);
        this.hapusItemDetail = this.hapusItemDetail.bind(this);
        this.editDetailTx = this.editDetailTx.bind(this);
        this.editDetailPakaian = this.editDetailPakaian.bind(this);
        this.changeOneDetailItem = this.changeOneDetailItem.bind(this);
        this.changePakaian = this.changePakaian.bind(this);
        this.updateDataPakaian = this.updateDataPakaian.bind(this);
        this.swalDeleteDetail = this.swalDeleteDetail.bind(this);
        this.swalDeletePromo = this.swalDeletePromo.bind(this);
        this.swalLanjut = this.swalLanjut.bind(this);
        this.cariPromo = this.cariPromo.bind(this);
        this.hapusPromo = this.hapusPromo.bind(this);
        this.addPromoVoc = this.addPromoVoc.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('token') === null){
            history.replace('/')
        }else{
            const role = JSON.parse(localStorage.getItem('role'));
            if(role.ksr != 1){
                swalKC.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Maaf anda tidak memiliki akses di fitur ini',
                    showCancelButton: false,
                    confirmButtonText: 'Kembali'
                  }).then((result) => {
                    history.goBack()
                  })
            }
            
            this.cariItem()
            this.getKategori()
            this.getItemDetail()
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    cariItem(kode_kategori = null){
        let kategori = this.state.kd_kategori;
        if(kode_kategori != null){
            this.setState({kd_kategori: kode_kategori})
            kategori = kode_kategori
        }
        if(kode_kategori === 'reset'){
            this.setState({kd_kategori: ''})
            kategori = ''
        }
        fetch(REACT_APP_API_URL+'get-item-reg', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                keyword: this.state.keywords,
                kategori: kategori,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({
                    item: responseJson.data
                })                
            }else{
                this.setState({
                    item: []
                })        
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }
    
    getKategori(){
        fetch(REACT_APP_API_URL+'get-item-kategori', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson.status)
            // console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({
                    kategori: responseJson.data
                })                
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }


    addItemDetail(kdItem){
        fetch(REACT_APP_API_URL+'add-item-detail', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdCust: this.query.get('kdCust'),
                kdItem: kdItem,
                kdHead: this.state.kdHeadPakaian,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            this.setState({ modalItem: false })
            this.getItemDetail()
        })
        .catch((error) => {
            console.error(error);
        });
    }


    getItemDetail(){
        const kdCust = this.query.get('kdCust')
        let _ts = this
        this.setState({disabled:true})

        fetch(REACT_APP_API_URL+'get-item-detail', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdCust: kdCust,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('data_detail')
            console.log(responseJson.data)
            if(responseJson.status === true){
                _ts.setState({
                    total_gross: responseJson.data.bruto,
                    disc_amount: responseJson.data.diskon,
                    pembulatan: responseJson.data.pembulatan,
                    nett: responseJson.data.nett,
                    txDetail: responseJson.data.detail,
                    kdHeadPakaian: responseJson.data.kode_transaksi_tmp,
                    nama_promo: responseJson.data.nama_promo
                })
            }
            _ts.setState({disabled:false})
        })
        .catch((error) => {
            console.error(error);
            _ts.setState({disabled:false})
        });
    }


    hapusItemDetail(){
        const kdDetail = this.state.kdDetPakaian
        const kdHead = this.state.kdHeadPakaian

        fetch(REACT_APP_API_URL+'hapus-item-temp', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdDet: kdDetail,
                kdHead: kdHead,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            this.getItemDetail()
            swalKC.fire({
                title: 'Sukses!',
                text: "Item berhasil di hapus!",
                icon: 'success'
            })

        })
        .catch((error) => {
            console.error(error);
            this.getItemDetail()
        });
    }

    editDetailTx(kdDetail, kdHead){
        fetch(REACT_APP_API_URL+'get-item-detail-one', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                detail_tx: kdDetail,
                kode_transaksi_tmp: kdHead,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)

            this.setState({ 
                txOneDetail: responseJson.data,
                kdHeadPakaian: kdHead,
                kdDetPakaian: kdDetail,
                modalItemDetail: true
             })
        })
        .catch((error) => {
            console.error(error);
        });
    }

    changeOneDetailItem(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        
        this.state.txOneDetail[name] = value;
        this.forceUpdate()
    }

    updateOneDetailItem(){
        this.setState({disabled:true})
        fetch(REACT_APP_API_URL+'update-item-detail-one', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdHead: this.state.kdHeadPakaian,
                kdDet: this.state.kdDetPakaian,
                dataItem: this.state.txOneDetail,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            
            if(responseJson.status === true){
                this.setState({
                    disabled: false, 
                    kdDetPakaian: '',
                    modalItemDetail: false
                })
            }

            this.getItemDetail()

        })
        .catch((error) => {
            console.error(error);
            this.setState({ disabled: false })
            this.getItemDetail()
        });
    }



    // function  pakaian
    editDetailPakaian(kdDetail, kdHead){
        fetch(REACT_APP_API_URL+'get-pakaian', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                detail_tx: kdDetail,
                kode_transaksi_tmp: kdHead,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)

            this.setState({ 
                modalPakaian: true, 
                pakaian: responseJson.data,
                kdHeadPakaian: kdHead,
                kdDetPakaian: kdDetail
             })
        })
        .catch((error) => {
            console.error(error);
        });
    }

    updateDataPakaian(){
        this.setState({disabled:true})
        fetch(REACT_APP_API_URL+'update-pakaian', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                pakaian: this.state.pakaian,
                kdHeadPakaian: this.state.kdHeadPakaian,
                kdDetPakaian: this.state.kdDetPakaian,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            
            if(responseJson.status === true){
                this.setState({
                    disabled: false, 
                    modalPakaian: false, 
                    kdDetPakaian: ''
                })
            }

            this.getItemDetail()

        })
        .catch((error) => {
            console.error(error);
            this.setState({ disabled: false })
            this.getItemDetail()
        });
        
        
    }

    changePakaian(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        
        this.state.pakaian.map((val) => {
            if(parseInt(val.kode_pakaian) === parseInt(name)){
                val.jumlah_pakaian = value.replace(/\D/g,'') * 1
            }
        })
        this.forceUpdate()
    }
    // sampai di sini function pakaian



    // Promo
    // 1 = promo, 2 = voucher
    cariPromo(jenis = 1){
        fetch(REACT_APP_API_URL+'get-promo-voucher', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                jenis: jenis,
                kdHead: this.state.kdHeadPakaian,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            console.log(responseJson.data.pembayaran)
            if(responseJson.status === true){
                this.setState({
                    jenis_promo: responseJson.data,
                    jenis: jenis,
                })                
            }else{
                this.setState({
                    jenis_promo: [],
                    jenis: jenis,
                })        
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }



    // swal
    swalDeleteDetail(kdHeadPakaian, kdDetPakaian){
        swalKC.fire({
            title: 'Anda yakin?',
            text: "Transaksi tidak dapat dikembalikan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Ya, Hapus!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({kdHeadPakaian: kdHeadPakaian, kdDetPakaian: kdDetPakaian})
                this.hapusItemDetail()
            }
          })
    }

    // swal
    swalDeletePromo(){
        swalKC.fire({
            title: 'Anda yakin?',
            text: "Promo / Voucher akan dibatalkan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Ya, Batalkan!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.hapusPromo()
            }
          })
    }


    addPromoVoc(kode_promo, jenis, kode_detail = null){
        fetch(REACT_APP_API_URL+'use-trx-promo-voc', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdHead: this.state.kdHeadPakaian,
                kode_promo: kode_promo,
                kode_detail: kode_detail,
                jenis: jenis,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            // console.log(responseJson.data.pembayaran)
            this.setState({ modalPromo: false })
            this.getItemDetail()
        })
        .catch((error) => {
            console.error(error);
            this.setState({ modalPromo: false })
            this.getItemDetail()
        });
    }


    hapusPromo(){
        const kdHead = this.state.kdHeadPakaian

        fetch(REACT_APP_API_URL+'hapus-promo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdHead: kdHead,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('response hapus')
            console.log(responseJson)
            if(responseJson.status === true){
                swalKC.fire({
                    title: 'Sukses!',
                    text: "Promo / voucher di hapus!",
                    icon: 'success'
                })
            }
            this.getItemDetail()

        })
        .catch((error) => {
            console.error(error);
            this.getItemDetail()
        });
    }

    lanjuttTrx(){
        const kdHead = this.state.kdHeadPakaian

        fetch(REACT_APP_API_URL+'simpan-transaksi', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                kode_user: localStorage.getItem('kode_user'),
                schema: localStorage.getItem('schema'),
                kdHead: kdHead,
                api_token: localStorage.getItem('token')
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if(responseJson.status === true){
                history.replace('/up-foto?kdTx='+responseJson.data.kdHead)  
            }
        })
        .catch((error) => {
            console.error(error);
        });
    }



    swalLanjut(){
        swalKC.fire({
            title: 'Simpan transaksi?',
            text: "Transaksi akan di simpan!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonText: 'Ya, Simpan!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.lanjuttTrx()
            }
          })
    }


    render() {
        const dataKategori = this.state.kategori.map((val, id) => {
            return (
                <Button key={val.kode_kategori} variant="outline-primary" className="btn-sm ml-1" onClick={() => this.cariItem(val.kode_kategori)}>{ucWords(val.nama_kategori)}</Button>
            );
        });

        const dataPakaian = this.state.pakaian.map((val, id) => {
            return (
                <div key={val.kode_pakaian} className="text-grey border-bottom row w-100 m-0">
                    <div className="col-8 p-0">
                        <div className="m-1 p-2">
                            <div className="font-weight-bold">{ucWords(val.nama_pakaian)}</div>
                        </div>
                    </div>
                    <div className="col-4 p-2">
                        <InputGroup>
                            <Form.Control
                            type="text"
                            placeholder="Total Item"
                            className="br0 form-control-sm p0"
                            name={val.kode_pakaian} 
                            value={val.jumlah_pakaian}
                            onChange={this.changePakaian}
                            />
                            <InputGroup.Prepend>
                              <InputGroup.Text className="form-control-sm" style={{fontSize:'.875rem'}}>PCS</InputGroup.Text>
                            </InputGroup.Prepend>
                            
                        </InputGroup>
                    </div>
                </div>
            );
        });

        const dataPromoVoc = this.state.jenis_promo.map((val, id) => {
            //jenis promo
            if(this.state.jenis == 1){
                return (
                    <div key={val.kode_promo} className="text-grey border-bottom " onClick={() => this.addPromoVoc(val.kode_promo, 1)}>
                        <div className="row w-100 m-0">
                            <div className="col-12 p-0">
                                <div className="m-1 p-1 text-left">
                                    <div className="font-weight-bold">{ucWords(val.nama_promo)}</div>
                                    <div className="font-10">{formatDateDFY(val.mulai)} - {formatDateDFY(val.akhir)}</div>
                                    <div className="font-10">Min. Order Rp {formatMoney(val.min_order)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            //jenis voucher
            if(this.state.jenis == 2){
                if(val.detail.length > 0){
                    return (
                        <div key={val.kode_voucher+val.kode_transaksi_voucher} className="text-grey border mt-2 p-2" onClick={() => this.addPromoVoc(val.kode_voucher, 2, val.kode_transaksi_voucher)}>
                            <div className="row w-100 m-0">
                                <div className="col-12 p-0">
                                    <div className="mb-2 text-left">
                                        <div className="font-weight-bold">{ucWords(val.nama_voucher)}</div>
                                        <div className="font-10 font-weight-bold">{val.show_kode_voucher} - {formatDateDFY(val.detail[0].tanggal_akhir)}</div>
                                    </div>
                                </div>
                            </div>


                            <div className="row w-100 m-0">
                                <div className="col-4 p-0 text-left">Ttl. Voc.</div>
                                <div className="col-4 p-0 text-left">Ttl. Pakai</div>
                                <div className="col-4 p-0 text-left">Sisa</div>
                            </div>
                            {
                                val.detail.map((v, i) => {
                                    return(
                                        <div className="row w-100 m-0 border-top" key={i} >
                                            <div className="col-12 p-0 text-left">{v.nama_item}</div>
                                            <div className="col-4 p-0 text-left">{formatAngka(v.isi_voucher)}</div>
                                            <div className="col-4 p-0 text-left">{formatAngka(v.total_pakai)}</div>
                                            <div className="col-4 p-0 text-left">{formatAngka(v.sisa)}</div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    );
                }
            }

        });

        const dataItem = this.state.item.map((val, id) => {
            return (
                <div key={val.kode_item} className="text-grey border-bottom row w-100 m-0" onClick={() => this.addItemDetail(val.kode_item)}>
                    <div className="col-9 p-0">
                        <div className="m-1 p-1">
                            <div className="font-weight-bold">{ucWords(val.nama_item)}</div>
                            <div>@ Rp {formatMoney(val.harga_layanan)}</div>
                            <div className="font-10">{val.lama_hari} Hari, {val.lama_jam} Jam </div>
                        </div>
                    </div>
                    <div className="col-3 p-0">
                        <div className="m-1 p-1 text-right">
                            <h3 className="color-kc"><BsPlusSquare /></h3>
                        </div>
                    </div>
                </div>
            );
        });

        const detailItemTx = this.state.txDetail.map((val, id) => {
            return (
                <div key={val.kode_detail_tx} className="border m-2">

                    <div className="w-100 border-bottom" >
                        <div className="row m-0">
                            <div className="col-10 p-0"  onClick={() => this.editDetailTx(val.kode_detail_tx, val.kode_transaksi_tmp)}>
                                <div className="font-weight-bold  p-1 pl-2">{ucWords(val.nama_item)}</div>
                            </div>
                            <div className="col-2 p-0 text-right" onClick={() => this.swalDeleteDetail(val.kode_transaksi_tmp, val.kode_detail_tx)}>
                                <h5 className="text-danger pr-1"><IoCloseCircle /></h5>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 border-bottom">
                        <div className="row m-0"   onClick={() => this.editDetailTx(val.kode_detail_tx, val.kode_transaksi_tmp)}>
                            <div className="col-6 p-0">
                                <div className=" p-1 pl-2">
                                    <div className="font-10">{val.lama_hari} Hari, {val.lama_jam} Jam </div>
                                    <div className="font-weight-bold">Rp {formatMoney(val.harga_awal)}</div>
                                </div>
                            </div>

                            <div className="col-6 p-0 text-right">
                                <div className="p-1 pl-2">
                                    <div>{formatAngka(val.total_item)} {val.satuan === 'KG' ? 'KG' : 'PCS'}</div>
                                    { val.satuan === 'M' ?
                                    <div>{formatAngka(val.panjang_item)} {val.satuan} x {formatAngka(val.lebar_item)} {val.satuan} = {formatAngka(val.total_luas)} {val.satuan}</div>
                                    :null }
                                </div>
                            </div>
                        </div>

                        {val.satuan === 'KG' ? 
                            <div>
                                <Button variant="light"  className="btn-light btn-kc-outline text-white btn-sm br0" block onClick={() => this.editDetailPakaian(val.kode_detail_tx, val.kode_transaksi_tmp)} ><span className="color-kc">Tambah Pakaian</span></Button>
                                {
                                    val.jenis_pakaian != null ?
                                        JSON.parse(val.jenis_pakaian).map((v, i) => {
                                            return(
                                            <div key={v.kode_pakaian} className="text-grey border-bottom row w-100 m-0">
                                                <div className="col-8 p-0">
                                                    <div className="m-1 font-10">
                                                        <div>{ucWords(v.nama_pakaian)}</div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="m-1 text-right font-10">{v.jumlah_pakaian} PCS</div>
                                                </div>
                                            </div>
                                        )})
                                    : null
                                }
                            </div>
                        : null}
                    </div>

                    <div className="p-1 pl-2 font-10 border-bottom" onClick={() => this.editDetailTx(val.kode_detail_tx, val.kode_transaksi_tmp)}>
                        {
                            val.total_luas == 0 ?
                                // kalau jenis itemnya kg atau satuan
                                parseFloat(val.min_order_reguler) > parseFloat(val.total_item) ?
                                <div className="text-danger font-weight-bold">
                                    * Luas / minimal jumlah order kurang dari {val.min_order_reguler} {val.satuan}
                                </div>
                                : null
                            :
                                // kalau jenis itemnya m
                                parseFloat(val.min_order_reguler) > parseFloat(val.total_luas) ?
                                <div className="text-danger font-weight-bold">
                                    * Luas / minimal jumlah order kurang dari {val.min_order_reguler} {val.satuan}
                                </div>
                                : null
                        }

                        <div>
                            <span className="font-weight-bold">Catatan : </span>{val.notes ? val.notes : '-'}
                        </div>
                    </div>

                    <div className="w-100 border-bottom" onClick={() => this.editDetailTx(val.kode_detail_tx, val.kode_transaksi_tmp)}>
                        <div className="row m-0">
                            <div className="col-6 p-0">
                                <div className=" p-1 pl-2">
                                    {
                                        val.total_harga !== val.total_nett ?
                                        <div className="font-10 line-through">Rp {formatMoney(val.total_harga)}</div>
                                        : null
                                    }
                                    <div className="font-weight-bold">Rp {formatMoney(val.total_nett)}</div>
                                </div>
                            </div>

                            <div className="col-6 p-0 text-right">
                                <div className="p-1 pl-2">
                                    <div>{val.show_kode_item}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        });

        return (
            <div>
                <div className="fixed-position bg-white">
                    <div className="header border-bottom shadow-sm">
                        <div className="row">
                            <div className="col-1" onClick={this.props.history.goBack}>
                                <h5><IoArrowBack /></h5>
                            </div>
                            <div className="col-11">
                                <h4>Transaksi Reguler</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {
                        this.state.txDetail.length === 0 ?
                        <div className="pt-reg">
                            <Container className="pt-40">
                                <div className="text-center">Belum ada transaksi</div>
                                <div className="text-center">Klik + untuk menambahkan item transaksi</div>
                            </Container>
                        </div>
                        :
                        <div className="pt-reg pb-reg">
                        {
                            this.state.nama_promo != '' ?
                            <div className="alert alert-success m-2 p-1 pl-2 mb-3" role="alert">
                                {this.state.nama_promo}
                                <IoCloseCircle className="float-right mt-1 text-danger"  onClick={() => this.swalDeletePromo()}/>
                            </div>
                            : null
                        }
        
                        {detailItemTx}
                        </div>
                    }

                    <div className="fix-bottom">
                        
                        <div className="p-1">
                            <div className="price-bg border br5">
                                <Table className="mb-0">
                                <tbody>
                                    <tr>
                                        <td className="p-1">Total Gross</td>
                                        <td className="p-1 text-right">Rp {formatMoney(this.state.total_gross)}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">Total Diskon</td>
                                        <td className="p-1 text-right">Rp {formatMoney(this.state.disc_amount)}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">Pembulatan</td>
                                        <td className="p-1 text-right">Rp {formatMoney(this.state.pembulatan)}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-1">Nett</td>
                                        <td className="p-1 text-right">Rp {formatMoney(this.state.nett)}</td>
                                    </tr>
                                </tbody>
                                </Table>
                            </div>
                        </div>

                        <Container>
                            <div className="row">
                                <div className="col-4  p-1">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={() => this.setState({ modalItem: true })}>Tambah <TiPlusOutline /> </Button>
                                </div>
                                <div className="col-4  p-1">
                                    <Button className="btn-light w-100 btn-kc text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={() => { this.setState({ modalPromo: true }); this.cariPromo(1); }}>Promo <TiTag /></Button>
                                </div>
                                <div className="col-4  p-1">
                                    <Button className="btn-light w-100 btn-kc-outline text-white" disabled={this.state.disabled ? 'disabled' : ''} block onClick={ () => this.swalLanjut() } ><span className="color-kc">Lanjut <TiArrowRightOutline /></span></Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                

                {/* modal list item */}
                <Modal
                    show={this.state.modalItem}
                    onHide={() => this.setState({ modalItem: false })}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body className="p-2">

                        <div className="fixed-position bg-white w-90 border-bottom">
                            <div className="modal-item-head mb-2">
                                <InputGroup>
                                    <Form.Control
                                    type="text"
                                    placeholder="Cari Item"
                                    className="br0 form-input pr-5"
                                    name="keywords" 
                                    value={this.state.keywords}
                                    onChange={this.handleInputChange}
                                    />
                                    <h3 className="ic-fl-rg text-black-50" onClick={() => this.cariItem()}>
                                        <RiSearchEyeLine/>
                                    </h3>
                                </InputGroup>
                                <h3 className="text-danger ml-2" onClick={() => this.setState({ modalItem: false })}><IoCloseCircle/></h3>
                            </div>

                            <div className="btn-kategori">
                                <Button variant="outline-primary" className="btn-sm" onClick={() => this.cariItem('reset')}>Semua Item</Button> {' '}
                                {dataKategori}
                            </div>
                        </div>
                        
                        <div  className="modal-item">
                            {dataItem}
                        </div>
                    </Modal.Body>
                </Modal>




                {/* modal detail item */}
                <Modal
                    show={this.state.modalItemDetail}
                    onHide={() => this.setState({ modalItemDetail: false })}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body className="p-2">
                        
                    <div className="row">

                        { this.state.txOneDetail.satuan === 'M' ?
                        <div className="col-6">
                            Panjang
                            <InputGroup>
                                <Form.Control
                                type="number"
                                placeholder="Panjang Item"
                                className="br0 form-control-sm p0"
                                name="panjang_item"
                                value={this.state.txOneDetail.panjang_item}
                                onChange={this.changeOneDetailItem}
                                />
                                <InputGroup.Prepend>
                                <InputGroup.Text className="form-control-sm" style={{fontSize:'.875rem'}}>{this.state.txOneDetail.satuan}</InputGroup.Text>
                                </InputGroup.Prepend>
                                
                            </InputGroup>
                        </div>
                        :null}

                        { this.state.txOneDetail.satuan === 'M' ?
                        <div className="col-6">
                            Lebar
                            <InputGroup>
                                <Form.Control
                                type="number"
                                placeholder="Lebar Item"
                                className="br0 form-control-sm p0"
                                name="lebar_item"
                                value={this.state.txOneDetail.lebar_item}
                                onChange={this.changeOneDetailItem}
                                />
                                <InputGroup.Prepend>
                                <InputGroup.Text className="form-control-sm" style={{fontSize:'.875rem'}}>{this.state.txOneDetail.satuan}</InputGroup.Text>
                                </InputGroup.Prepend>
                                
                            </InputGroup>
                        </div>
                        :null}

                        <div className="col-12 mt-3">
                            Total Item
                            <InputGroup>
                                <Form.Control
                                type="number"
                                placeholder="Total Item"
                                className="br0 form-control-sm p0"
                                name="total_item"
                                value={this.state.txOneDetail.total_item}
                                onChange={this.changeOneDetailItem}
                                />
                                <InputGroup.Prepend>
                                <InputGroup.Text className="form-control-sm" style={{fontSize:'.875rem'}}>{this.state.txOneDetail.satuan === 'KG' ? 'KG' : 'PCS'}</InputGroup.Text>
                                </InputGroup.Prepend>
                                
                            </InputGroup>
                        </div>
                        

                        <div className="col-12 mt-3">
                            Catatan : 
                            <textarea className="br0 form-control w-100" value={this.state.txOneDetail.notes} name="notes" onChange={this.changeOneDetailItem} />
                        </div>
                    </div>


                    </Modal.Body>
                    <Modal.Footer className="p-2">
                        <Button variant="light" className="btn-outline-dark btn-sm" onClick={() => this.setState({ modalItemDetail: false })}>
                            Batal
                        </Button>
                        <Button variant="light" className="btn-kc-outline btn-sm color-kc" onClick={() => this.updateOneDetailItem()}>
                            Ubah Detail Item
                        </Button>
                    </Modal.Footer>
                </Modal>


                {/* modal pakaian */}
                <Modal
                    show={this.state.modalPakaian}
                    onHide={() => this.setState({ modalPakaian: false })}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body className="p-2">
                        <div  className="modal-item-detail">
                        {dataPakaian}
                        </div>
                        <div className="border-top p-2">
                            <div className="float-right">
                                <Button variant="light" className="btn-outline-dark btn-sm" onClick={() => this.setState({ modalPakaian: false })}>
                                    Batal
                                </Button>
                                {' '}
                                <Button variant="light" className="btn-kc-outline btn-sm color-kc" onClick={() => this.updateDataPakaian()}>
                                    Ubah Pakaian
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>



                {/* modal promo */}
                <Modal
                    show={this.state.modalPromo}
                    onHide={() => this.setState({ modalPromo: false })}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body className="p-2 text-center">

                        <div className="row">
                            <div className="col-5 pr-0">
                                <Button variant="outline-primary" className="btn-sm" block onClick={() => this.cariPromo(1)}>Promo</Button> {' '}
                            </div>
                            <div className="col-5 p-0 pl-1">
                                <Button variant="outline-primary" className="btn-sm" block onClick={() => this.cariPromo(2)}>Voucher</Button> {' '}
                            </div>
                            <div className="col-2">
                                <BsXCircleFill style={{fontSize:25}} className="mr-3 mt-1 text-danger" onClick={() => this.setState({ modalPromo: false })}/>
                            </div>
                        </div>

                        <div  className="modal-item-promo">
                        {dataPromoVoc}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}



export default DashboardTransaksi;
